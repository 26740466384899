<template>
    <q-layout>
        <q-page-container>
            <router-view v-slot="{ Component }" :style-fn="(o) => ({ minHeight: o ? `calc(100vh - ${o}px)` : '100vh' })">
                <transition appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                    <component :is="Component" />
                </transition>
            </router-view>
        </q-page-container>
    </q-layout>
</template>

<script lang="js">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'BaseLayout'
});
</script>

<style lang="scss" scoped>

</style>
